(function ($, undefined) {

    $(document)
        /**
         * Computes movement to perform and trigger event
         *
         * @param  Event   evt      jQuery.Event triggered
         *
         * @return jQuery
         */
        .on('move.position', '[data-position]', function (evt) {
            var $this = $(this),
                $input      = $this.closest('.position').find('input'),
                items       = $this.closest('[data-parent-selector]').children(),
                index       = -1
            ;

            // Lookup index containing input
            for (var idx in items) {
                if ($.contains(items[idx], $input.get(0))) {
                    index = parseInt(idx, 10);
                    break;
                }
            }

            if (-1 === index) {
                return;
            }

            $(items.get(index)).addClass('bg-info').delay('slow').stop().animate({
                'background-color' : 'default'
            }, 'slow', 'swing', function () {
                $(this).removeClass('bg-info').css('background-color', '');
            });

            $this
                .trigger('move-' + $this.data('position') + '.position', [index])
                .trigger('reindex.position')
            ;

        })

        /**
         * Move element at the top of the collection
         */
        .on('move-top.position', '[data-position]', function (evt, index) {
            if (undefined === index) {
                return;
            }

            var $this = $(this),
                items = $this.closest('[data-parent-selector]').children()
            ;

            if (0 < index) {
                $(items.get(0)).before(items.get(index));
            }
        })

        /**
         * Move element at the index up in the collection
         *
         * @param  jQuery.Event evt   jQuery Event triggered
         * @param  Number       index Actual position of the element to move up
         *
         * @return jQuery
         */
        .on('move-up.position', '[data-position]', function (evt, index) {
            if (undefined === index) {
                return;
            }

            var $this = $(this),
                items = $this.closest('[data-parent-selector]').children()
            ;

            if (0 < index) {
                $(items.get(index -1)).before(items.get(index));
            }
        })

        /**
         * Move element at the index down in the collection
         *
         * @param  jQuery.Event evt   jQuery Event triggered
         * @param  Number       index Actual position of the element to move down
         *
         * @return jQuery
         */
        .on('move-down.position', '[data-position]', function(evt, index) {
            if (undefined === index) {
                return;
            }

            var $this = $(this),
                items = $this.closest('[data-parent-selector]').children()
            ;

            $(items.get(index + 1)).after(items.get(index));
        })

        /**
         * Move element at the top of the collection
         */
        .on('move-bottom.position', '[data-position]', function (evt, index) {
            if (undefined === index) {
                return;
            }

            var $this = $(this),
                items = $this.closest('[data-parent-selector]').children()
            ;

            $(items.get(items.length - 1)).after(items.get(index));

        })

        /**
         * Computes position of all widgets within the collection
         *
         * @return jQuery
         */
        .on('reindex.position', '[data-position]', function () {
            var $collection = $(this).closest('[data-parent-selector]');
            $collection.children().find('.position > input').each(function (idx) {
                $(this).val(idx + 1);
            });
        })
    ;


    $(document)
        .on('click', '[data-position]', function () {
            $(this).trigger('move.position');
        })
        .on('add.collection', '[data-parent-selector]', function () {
            $('[data-position]').trigger('reindex.position');
        })
        .ready(function () {
            $('[data-position]').trigger('reindex.position');
        })
    ;

})(jQuery);
